<template>
  <v-app-bar
    app
    dense
    clipped-left
    clipped-right
    :class="appEnvironmentStyle"
    style="z-index: 100;"
  >
    <v-toolbar-title
      class="d-flex align-center"
      style="cursor: pointer"
      @click="$router.push(user.homeUrl)"
    >
      <img
        alt="TPA Solutions"
        src="../../assets/logo.png"
        height="32"
      >

      <div class="ml-3 d-flex align-center">
        <img v-if="$store.getters.currentCustomer.title === 'Udenrigsministeriet'" src="../../assets/um-logo-black.png" alt="Udenrigsministeriet">
        <svg v-else-if="$store.getters.currentCustomer.title === 'SDU'" width="83" height="22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 83 22" xml:space="preserve">                        
          <g fill="#000" fill-rule="evenodd"><path d="M2.38 16.65c1.085 1.142 2.74 2 4.474 2 1.63 0 2.77-.962 2.77-2.286 0-2.342-3.528-2.627-6.078-4.056C2.054 11.476.86 10.228.86 8.176c0-3.25 3.12-5.123 6.266-5.123 1.927 0 3.718.443 5.508 1.716l-1.9 2.6C9.975 6.692 8.7 6.17 7.453 6.17c-1.383 0-2.93.573-2.93 1.976 0 2.937 8.763 2.03 8.763 8.163 0 3.225-3.012 5.46-6.43 5.46-2.55 0-4.91-1.014-6.863-2.73l2.387-2.39M22.372 3.313c3.01 0 4.99.624 6.538 1.716 2.332 1.663 3.77 4.366 3.77 7.382 0 3.015-1.438 5.72-3.77 7.382-1.547 1.092-3.527 1.717-6.538 1.717h-5.97V3.314h5.97zm5.262 5.147c-1.06-1.273-2.604-2.027-4.856-2.027h-2.712V18.39h2.712c2.252 0 3.797-.753 4.856-2.026.868-1.042 1.384-2.445 1.384-3.952 0-1.508-.516-2.912-1.384-3.952zM38.103 19.898c-1.573-1.352-2.44-3.275-2.44-6.056V3.312h3.66v10.79c0 1.662.464 2.677 1.25 3.378.813.754 1.952 1.17 3.228 1.17 1.275 0 2.414-.416 3.23-1.17.785-.7 1.246-1.716 1.246-3.38V3.314h3.662v10.53c0 2.78-.867 4.703-2.44 6.055-1.466 1.248-3.555 1.872-5.697 1.872-2.143 0-4.232-.624-5.697-1.872M70.653 10.864c.243 1.148 1.184 2.75 4.212 3.18 1.82.255 6.052-.317 8.026-.774-1.31-2.193-3.69-4.636-6.636-5.307-2.963-.673-4.92.19-5.605 1.966M65.927 13.815s-.217-.182-.96-.19c-1.31-.015-2.224.56-2.86 1.923-.614 1.317-.19 3.387.64 4.583.47.674 1.29 1.52 2.27 1.52s1.257-.49 1.6-.49c.346 0 .664.478 1.683.478 1.02 0 1.825-.907 2.163-1.42.785-1.183 1.18-3.33.61-4.637-.494-1.135-1.337-1.925-2.422-1.937-.778-.01-1.527.272-1.527.272"></path><path d="M71.1 10.875L71.1 9.94c-1.747-.367-4.8-.333-7.66.342-1.66.392-4.993 1.337-8.213 3.542l.743.997c2.335-1.845 4.64-2.787 6.466-3.224 2.425-.58 3.71.977 3.57 2.56l1.184-.002c-.192-1.955-.98-3.002-2.352-3.293 1.47-.318 4.696-.523 6.265.015M66.412 4.234C69.21 1.43 74.742.39 74.742.39c-2.596 1.984-2.65 4.423-5.236 6.43-1.637 1.27-4.02 1.758-5.283 1.835.074-.767.142-2.367 2.19-4.42"></path></g>
        </svg>
      </div>
    </v-toolbar-title>

    <v-spacer />

    <v-toolbar-items class="hidden-sm-and-down align-center">
      <template v-for="(navItem, i) in user.navigationItems">
        <v-btn
          v-if="navItem.subItems.length==0"
          :key="i"
          :to="navItem.route"
          text
          small
        >
          <v-icon v-if="navItem.icon" left> {{ navItem.icon }} </v-icon>
          {{ navItem.title }}
          <v-icon v-if="navItem.beta" small> mdi-test-tube </v-icon>
        </v-btn>

        <v-menu
          v-else
          :key="i + '-menu'"
          :offset-y="true"
          min-width="200"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              text
              small
              v-bind="attrs"
              v-on="on"
              :class="{ 'v-btn--active': $route.path.includes(navItem.route) }"
            >
              <v-icon v-if="navItem.icon" :left="$vuetify.breakpoint.lgAndUp" :icon="$vuetify.breakpoint.mdAndDown"> {{ navItem.icon }} </v-icon>

              <span class="hidden-md-and-down">{{ navItem.title }}</span>
              <v-icon class="hidden-md-and-down" right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>

          <v-list class="pa-0" dense>
            <template v-for="(subItem, si) in navItem.subItems">
              <v-list-item
                :key="si"
                :to="subItem.route"
                exact
                :disabled="subItem.disabled"
              >
                <v-list-item-title>{{ subItem.title }}</v-list-item-title>
                <v-list-item-icon v-if="subItem.beta">
                  <v-icon small color="grey"> mdi-test-tube </v-icon>
                </v-list-item-icon>
              </v-list-item>

              <v-list-item
                v-for="item in subItem.subItems"
                :key="item.route"
                :to="item.route"
                dense
                :disabled="item.disabled"
                class="pl-8"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                  <v-list-item-icon v-if="subItem.beta">
                    <v-icon small color="grey"> mdi-test-tube </v-icon>
                  </v-list-item-icon>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </template>
    </v-toolbar-items>

    <v-btn
      text
      @click.native="$store.dispatch('toggleShowOnlyRelevantContent')"
    >
      <v-switch
        v-model="showOnlyRelevant"
        :loading="$store.state.loadingToggleShowOnlyRelevantContent"
        color="teal"
        readonly
        dense
        hide-details
      />
      <v-fade-transition leave-absolute>
        <v-icon
          :key="`show-${showOnlyRelevant}`"
          :color="showOnlyRelevant ? 'teal' : ''"
        >
          {{ showOnlyRelevant ? 'mdi-account' : 'mdi-account-off' }}
        </v-icon>
      </v-fade-transition>
    </v-btn>

    <v-menu :offset-y="true">
      <template #activator="{ on, attrs }">
        <v-btn
          class="hidden-sm-and-down"
          text
          small
          v-bind="attrs"
          v-on="on"
        >
          <div class="d-flex flex-column">
            <div>
              {{ user.fullName }}
            </div>
            <small v-if="$store.getters.userHasMultipleCustomers" class="mt-1" style="font-size: x-small;">
              {{ $store.getters.currentCustomer.title }}
            </small>
          </div>
        </v-btn>
      </template>

      <v-sheet>
        <v-list class="pa-0" dense>
          <v-list-item
            v-for="navItem in user.profileNavigationItems"
            :key="navItem.route"
            :to="navItem.route"
          >
            <v-list-item-title>{{ navItem.title }}</v-list-item-title>
            <v-list-item-icon>
              <v-icon> {{ getIcon(navItem.route) }} </v-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-divider />
          <v-list-item to="/about">
            <v-list-item-title>
              Om TPA Portalen
            </v-list-item-title>
            <v-list-item-icon>
              <v-icon> mdi-information-outline </v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list>

        <div class="d-flex justify-end">
          <v-btn-toggle
            dense
            multiple
            class="ma-3"
          >
            <v-btn
              icon
              @click.stop="toggleTheme"
            >
              <v-icon>
                {{ !$vuetify.theme.dark ? 'mdi-weather-sunny' : 'mdi-weather-sunny-off' }}
              </v-icon>
            </v-btn>
            <v-btn
              v-if="$store.getters.userHasMultipleCustomers"
              outlined
              @click="$store.commit('setPrimaryCustomerId', null)"
            >
              <v-icon class="mr-2"> mdi-swap-horizontal </v-icon>
              <v-icon> mdi-domain </v-icon>
            </v-btn>
          </v-btn-toggle>
        </div>
      </v-sheet>
    </v-menu>

    <v-toolbar-items class="hidden-md-and-up align-center">
      <v-menu :offset-y="true">
        <template #activator="{ on, attrs }">
          <v-btn
            class="hidden-md-and-up"
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
  
        <v-list subheader>
          <v-subheader>Navigation</v-subheader>            
          <v-list-item
            v-for="navItem in user.navigationItems"
            :key="navItem.route"
            :to="navItem.route"
          >              
            <v-list-item-title>{{ navItem.title }}</v-list-item-title>              
          </v-list-item>            
          <v-subheader>{{ user.fullName }}</v-subheader>            
          <v-list-item
            v-for="navItem in user.profileNavigationItems"
            :key="navItem.route"
            :to="navItem.route"
          >
            <v-list-item-icon>
              <v-icon> {{ getIcon(navItem.route) }} </v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ navItem.title }}</v-list-item-title>              
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
import config from '@/assets/config.json'

export default {
  name: 'Header',
  computed: {
    user() {
      return this.$store.getters.currentUser;
    },
    showOnlyRelevant: {
      get() {
        return this.$store.getters.showOnlyRelevantContent;
      },
      set(value) {
        this.$store.dispatch('toggleShowOnlyRelevantContent')
      }
    },
    appEnvironment() {
      return config.environment;
    },    
    appEnvironmentStyle() {
      var strValue = config.environment;
      if (strValue) {
        strValue=strValue.toLowerCase()+"EnvStyle"
      }
      return strValue;
    },
  },
  methods: {
    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;

      localStorage.setItem('prefers-dark', this.$vuetify.theme.dark);
    },
    getIcon(route) {
      switch (route) {
        case '/account':
          return 'mdi-card-account-details-outline'
        case '/help':
          return 'mdi-head-question-outline'
        case '/terms':
          return 'mdi-file-check-outline'
        case '/logoff':
          return 'mdi-logout-variant'
        default:
          return ''
      }
    },
    getLogoFilename(customerName) {
      switch (customerName) {
        case 'Udenrigsministeriet':
          return 'um-logo-black.png'
        case 'Syddansk Universitet':
          return ''
        default:
          return ''
      }
    }
  }
}
</script>

<style scoped>

</style>