<template>
  <v-fade-transition mode="out-in" group>
    <v-row v-if="$store.state.loading" key="loading">
      <v-col>
        <Loading :loading="true" text="Indlæser portalen" />
      </v-col>
    </v-row>

    <v-main v-else-if="readyForMain" key="main">
      <v-container fluid>
        <Header />
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-main v-else-if="!$store.getters.userHasAcceptedTerms" key="accept-terms">
      <AcceptTerms />
    </v-main>

    <v-main v-else-if="!!$store.getters.userHasMultipleCustomers && !$store.state.primaryCustomerId" key="chooseCustomer">
      <div class="d-flex flex-column align-center justify-center" style="height: 80vh; gap: 1rem;">
        <div>
          User needs to choose primary customer ID
        </div>

        <v-btn
          v-for="customer of $store.getters.currentUser.customers"
          :key="customer.title"
          @click="$store.commit('setPrimaryCustomerId', customer.id)"
        >
          {{ customer.title }}
        </v-btn>
      </div>
    </v-main>

    <v-main v-else key="error">
      <v-container class="d-flex flex-column align justify-center">
        <v-icon> mdi-home </v-icon>
        <div>
          Noget gik galt!
        </div>
      </v-container>
    </v-main>
  </v-fade-transition>
</template>

<script>
import Header from '@/components/layout/Header.vue'
import Loading from '@/components/Loading.vue'
import AcceptTerms from '@/components/AcceptTerms.vue'

export default {
  components: { Header, Loading, AcceptTerms },
  computed: {
    readyForMain() {
      return !!this.$store.getters.currentUser && !!this.$store.getters.userHasAcceptedTerms && !!this.$store.state.primaryCustomerId
    }
  }
}
</script>